.footer_links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px auto;
    border-top: 1px solid #ffffff7a;
    border-bottom: 1px solid #ffffff7a;
    padding: 12px 0;
    width: fit-content;
}


.footer_main {
    position: relative;
}

.footer_div {
    max-width: 500px;
    margin: auto;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 20px;
    color: #fff;
}

.footer_div p {
    margin-bottom: 0;
    margin-top: 20px;
    font-size: 12px;
}

/* .footer_div img {
    width: 80px;
} */

.footer_links a {
    margin-right: 25px;
    font-weight: 600;
    text-transform: uppercase;
    font-style: italic;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
}

.footer_links a:hover{
    color: #fff;
}

.footer_links p {
    margin-bottom: 0;
}

.footer_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 35px;
}

.footer_icon img {
    width: 25px;
    margin-right: 20px;
}

.footer_terms {
    display: flex;
    justify-content: center;
    font-size: 14px;
    margin-top: 10px;
}

.footer_terms p a {
    margin-right: 15px;
    font-weight: 700;
}

.footer_reserved {
    text-align: center;
    background: transparent;
    color: #fff;
    padding: 10px 0;
    border-top: 1px solid #ffffff7a;
}

.footer_reserved p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
}

.shadow_main {
    position: absolute;
    top: -18%;
    margin: auto;
    width: 100%;
    left: 24%;
}

.shadow_main img {
    height: 580px;
    opacity: 0.4;
}

.shadow_main2 {
    position: absolute;
    top: 15%;
    margin: auto;
    width: 100%;
    left: 38%;
}

.shadow_main2 img {
    height: 310px;
    opacity: 0.4;
}

@media (max-width: 1000px) {
    .shadow_main {
        display: none;
    }

    .shadow_main2 {
        display: none;
    }
}

@media (max-width: 767px) {
    .footer_terms {
        font-size: 12px;
    }

    .footer_reserved p {
        font-size: 13px;
        padding: 10px;
    }

    .footer_links{
        flex-wrap: wrap;
    }

    .footer_links a{
        margin-bottom: 10px;
    }
}