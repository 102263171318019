.crew_main h1 span {
    text-align: center;
    -webkit-text-stroke: 1px #e25822;
    -webkit-text-fill-color: transparent;
    font-size: 60px;
    font-weight: 900;
    margin-bottom: 0;
}

.crew_main h1 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
}

.crew_div {
    background: transparent;
    border: 1px solid #747474;
    backdrop-filter: blur(28px);
    --webkit-backdrop-filter: blur(28px);
    background-color: rgba(255, 255, 255, 0.07);
    padding: 15px;
    color: #fff;
    height: 100%;
    margin-bottom: 20px;
}

.crew_div h4 {
    font-size: 17px;
    margin-top: 10px;
    font-weight: 700;
    color: #e25822;
}

.crew_div h5 {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 700;
}

.crew_div:last-child img {
    width: 300px;
    height: 255px;
    object-fit: cover;
}

@media (max-width: 767px) {
    .crew_div{
        text-align: center;
        margin-bottom: 20px;
        height: auto;
    }

    .crew_div:last-child img {
        width: 300px;
        height: 320px;
        object-fit: cover;
    }
}