.producer_main {
    color: #fff;
    border-top: 2px solid #ffffff52;
    padding-top: 40px;
    margin-top: 50px;
}

.border_bottom {
    border-bottom: 2px solid #ffffff52;
    padding-bottom: 25px;
}

.producer_text h1 {
    -webkit-text-stroke: 1px #e25822;
    -webkit-text-fill-color: transparent;
    font-size: 45px;
    font-weight: 900;
    margin-bottom: 0;
}

.producer_text h3 {
    font-weight: 800;
}

.producer_text p {
    margin-top: 20px;
    font-size: 15px;
}

.producer_text {
    padding-left: 20px;
}

.producer_text h2 {
    font-size: 40px;
    font-weight: 800;
}

.asst_prod img {
    height: 600px;
    width: 100%;
    object-fit: cover;
}

.inside_box {
    background: transparent;
    border: 1px solid #747474;
    -webkit-backdrop-filter: blur(28px);
    backdrop-filter: blur(28px);
    --webkit-backdrop-filter: blur(28px);
    background-color: rgba(255, 255, 255, 0.07);
    padding: 40px;
    color: #fff;
}

.taimoor_director img {
    height: 295px;
    width: 546px;
    object-fit: cover;
    object-position: top;
}

.writer_main img {
    width: 545px;
    height: 310px;
    object-fit: cover;
}

@media (max-width: 767px) {
    .producer_text h1 {
        font-size: 35px;
        margin-top: 10px;
    }


    .asst_prod img {
        height: 370px;
    }

    .producer_text {
        padding-left: 0;
    }

    .make_reverse{
        flex-direction: column-reverse;
    }
}