.top_banner_main {
    background: transparent;
    border: 1px solid #747474;
    backdrop-filter: blur(28px);
    --webkit-backdrop-filter: blur(28px);
    background-color: rgba(255, 255, 255, 0.07);
    padding: 20px;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 20px;

}

.top_banner_main img {
    width: 1000px;
}

.top_banner_main a {
    border-radius: 40px;
    background: transparent;
    border: 1px solid #747474;
    backdrop-filter: blur(28px);
    --webkit-backdrop-filter: blur(28px);
    background-color: rgba(255, 255, 255, 0.07);
    margin-top: 20px;
    padding: 12px 40px;
    color: #fff;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 800;
    text-decoration: none;
}

.top_banner_main a:hover{
    color: #fff;
}

@media (max-width: 767px) {
    .top_banner_main img {
        width: 1000px;
        height: 500px;
        object-fit: cover;
    }

    .top_banner_main a {
        font-size: 13px;
        padding: 8px 30px;
    }
}