.songs_main{
    margin-top: 50px;
}

.main_trailer h4{
    color: #fff;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 50px;

}

@media (max-width: 767px) {
    .main_trailer h4{
        font-size: 25px;
        margin-bottom: 0;
    }
}