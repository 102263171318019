.main_about {
    background: transparent;
    border: 1px solid #747474;
    backdrop-filter: blur(28px);
    --webkit-backdrop-filter: blur(28px);
    background-color: rgba(255, 255, 255, 0.07);
    padding: 40px;
    color: #fff;
    margin: 65px 0;
}

.main_about ul {
    list-style-type: circle;
}

.main_about ul li {
    font-size: 17px;
    margin-bottom: 5px;
}

.about_logo {
    text-align: center;
}

.about_logo img {
    width: 350px;
}

.main_about h1 {
    font-size: 50px;
    font-weight: 900;
    font-size: 60px;
}

.main_about h1 span {
    text-align: center;
    -webkit-text-stroke: 1px #e25822;
    -webkit-text-fill-color: transparent;
    font-size: 60px;
    font-weight: 900;
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .main_about h1 {
        font-size: 40px;
    }

    .main_about h1 span {
        font-size: 40px;
    }

    .main_about {
        padding: 20px;
    }

    .our_vision_right {
        padding: 20px;
    }
}