.production_main {
    color: #fff;
    margin: 40px 0;
}

.production_main p {
    margin-top: 10px;
}

.center_prod img {
    height: 280px;
    width: 100%;
    object-fit: cover;
}

.prod_left2 {
    position: relative;
    bottom: 25px;
}

.prod_center2 {
    position: relative;
    top: 15px;
}

.prod_right2{
    position: relative;
    bottom: 20px;
}

@media (max-width: 767px) {
    .production_main .col-md-4{
        margin-bottom: 15px;
    }

    .prod_left2{
        bottom: 0;
    }
    
    .prod_center2{
        top: 0;
    }

    .prod_right2{
        bottom: 0;
    }
}