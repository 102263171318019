.activities_main {
    margin: 40px 0;
}

.activities_main h1 {
    text-align: center;
    -webkit-text-stroke: 1px #e25822;
    -webkit-text-fill-color: transparent;
    font-size: 60px;
    font-weight: 900;
    margin-bottom: 0;
    position: relative;
    right: 16%;
    top: 20px;
}

.activities_main h2 {
    text-align: center;
    color: #fff;
    font-size: 60px;
    font-weight: 900;
}

.activity_box {
    background: transparent;
    border: 1px solid #747474;
    -webkit-backdrop-filter: blur(28px);
    backdrop-filter: blur(28px);
    --webkit-backdrop-filter: blur(28px);
    background-color: rgba(255, 255, 255, 0.07);
    color: #fff;
    height: 100%;
    margin-bottom: 20px;
    margin-top: 30px;
}

.activity_box img {
    width: 100%;
    height: 225px;
    object-fit: cover;
    object-position: top;
}

.activity_box p {
    font-size: 19px;
    text-align: center;
    font-weight: 500;
    margin-top: 20px;
    padding: 15px;
}

.activity_box a {
    background-color: #e25822;
    color: #fff;
    border: none;
    padding: 10px 35px;
    font-weight: bold;
    border-radius: 10px;
    text-decoration: none;
}

.extra_marging {
    margin-top: 45px !important;
}


@media (max-width: 767px) {
    .activities_main h1 {
        text-align: center;
        -webkit-text-stroke: 1px #e25822;
        -webkit-text-fill-color: transparent;
        font-size: 35px;
        font-weight: 900;
        margin-bottom: 0;
        position: relative;
        right: 0;
        top: 0px;
    }

    .activities_main h2 {
        color: #fff;
        font-size: 35px;
        font-weight: 900;
        text-align: center;
    }
}