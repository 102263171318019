@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;

}

body {
  background: linear-gradient(to right, #17191c 0%, #000 100%);
  color: #fff;
}

img {
  max-width: 100%;
}

.teaser_iframe {
  text-align: center;
}

.teaser_iframe iframe {
  width: 950px;
  height: 450px;
}

@media (max-width: 767px) {
  .teaser_iframe iframe {
    width: auto;
    height: auto;
  }
}