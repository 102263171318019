.stars_div h1 span {
    text-align: center;
    -webkit-text-stroke: 1px #e25822;
    -webkit-text-fill-color: transparent;
    font-size: 60px;
    font-weight: 900;
    margin-bottom: 0;
}

.stars_div h1 {
    font-size: 60px;
    color: #fff;
    font-weight: 900;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
}

.star_main {
    display: flex;
}

.star_main div {
    padding: 10px;
    position: relative;
}

.star_main div h6 {
    position: absolute;
    color: #fff;
    background-color: #00000094;
    bottom: 0;
    font-size: 16px;
    padding: 20px 35px;
    font-weight: 800;
    letter-spacing: 1px;
}

.star7_div img {
    width: 500px;
}

.star6_div {
    position: relative;
    bottom: 160px;
}

.star6_div img {
    width: 500px;
}

.rashid_naz img {
    width: 700px;
    height: 275px;
    object-fit: cover;
    object-position: top;
}

.adnan_shah {
    top: -150px;
}

.adnan_shah img {
    width: 600px;
    height: 430px;
    object-fit: cover;
}

.areej_chaud {
    top: -160px;
}

.areej_chaud img {
    width: 600px;
    height: 467px;
    object-fit: cover;
}

.anya_khan {
    bottom: 160px;
}

.anya_khan img {
    width: 550px;
    height: 400px;
    object-fit: cover;
}

.star5_div {
    bottom: 160px;
}

.star5_div img {
    width: 500px;
}

.pakiza {
    bottom: 160px;
}

.pakiza img {
    width: 500px;
    height: 400px;
    object-fit: cover;
    object-position: top;
}

.saleem img {
    height: 275px;
    width: 715px;
}

.dummy_side {
    bottom: 160px;
}

.dummy_side img {
    height: 400px;
    object-fit: cover;
    width: 440px;
}

.work_together {
    max-width: 800px;
    text-align: start !important;
    text-transform: uppercase;
    position: relative;
    bottom: 100px;
    font-style: italic;
    font-size: 70px !important;
}

.humaiyon_div img{
    width: 420px;
    height: 400px;
    object-fit: cover;
    object-position: top;
}

@media (max-width: 767px) {
    .stars_div h1 span {
        font-size: 45px;
    }

    .stars_div h1 {
        font-size: 45px !important;
    }

    .star_main {
        flex-direction: column;
    }

    .star6_div {
        bottom: 0;
    }

    .adnan_shah {
        top: 0;
    }

    .areej_chaud {
        top: 0;
    }

    .anya_khan {
        bottom: 0;
    }

    .star5_div {
        bottom: 0;
    }

    .pakiza {
        bottom: 0;
    }

    .dummy_side {
        bottom: 0;
    }

    .work_together {
        bottom: 10px;
    }
}