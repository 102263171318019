.player_main {
    display: flex;
    justify-content: center;

    background: transparent;
    border: 1px solid #747474;
    -webkit-backdrop-filter: blur(28px);
    backdrop-filter: blur(28px);
    --webkit-backdrop-filter: blur(28px);
    background-color: rgba(255, 255, 255, 0.07);
    padding: 20px;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 20px;
}

.main_trailer {
    margin: 40px 0;
}

.main_trailer h1 {
    text-align: center;
    -webkit-text-stroke: 1px #e25822;
    -webkit-text-fill-color: transparent;
    font-size: 60px;
    font-weight: 900;
    margin-bottom: 0;
    position: relative;
    right: 16%;
    top: 20px;
}

.main_trailer h2 {
    text-align: center;
    color: #fff;
    font-size: 60px;
    font-weight: 900;
}

.scroll_top {
    margin: 50px 0;
    text-align: center;
}

.scroll_top img {
    cursor: pointer;
    width: 80px;
}

.player_div {
    width: 1000px !important;
    height: 500px !important;
}

.player_main iframe{
    width: 950px;
    height: 450px;
}

@media (max-width: 767px) {
    .main_trailer h1 {
        font-size: 45px;
        top: 10px;
        right: 0;
    }

    .main_trailer h2 {
        font-size: 45px;
    }

    .player_div {
        width: 1000px !important;
        height: 275px !important;
    }

    .scroll_top img {
        cursor: pointer;
        width: 60px;
    }

    .player_main {
        padding: 0;
    }

}