.vision_main {
    color: #fff;
    margin: 80px 0;
}

.vision_left p {
    margin-top: 10px;
    font-size: 15px;
}

.our_vision_right {
    background: transparent;
    border: 1px solid #747474;
    backdrop-filter: blur(28px);
    --webkit-backdrop-filter: blur(28px);
    background-color: rgba(255, 255, 255, 0.07);
    padding: 40px;
    color: #fff;
}

.our_vision_right h1 {
    font-size: 50px;
    font-weight: 900;
    font-size: 60px;
}

.our_vision_right h1 span {
    text-align: center;
    -webkit-text-stroke: 1px #e25822;
    -webkit-text-fill-color: transparent;
    font-size: 60px;
    font-weight: 900;
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .our_vision_right h1 {
        font-size: 40px;
    }

    .our_vision_right h1 span {
        font-size: 40px;
    }

    .our_vision_right {
        padding: 20px;
        margin-top: 20px;
    }
}