.navbar {
    background: transparent;
    border: 1px solid #81818173;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    --webkit-backdrop-filter: blur(5px);
    background-color: rgb(255 255 255 / 1%);
    padding: 0 !important;
}

.nav-link {
    color: #fff !important;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 700 !important;
    margin-right: 10px;
}

.navbar-brand img{
    width: 250px;
}

button.navbar-toggler.collapsed .navbar-toggler-icon {
    background-image: url('../../../public/images/nav-icon.png') !important;
    transition: all 0.3s ease-in-out;
    width: 30px;
}


.navbar-toggler .navbar-toggler-icon{
    background-image: url('../../../public/images/close.png') !important;
    width: 20px;
    transition: all 0.3s ease-in-out;
}