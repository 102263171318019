.release_text {
    color: #fff;
}

.release_text h1 {
    font-size: 50px;
    font-weight: 900;
}

.release_text h1 span {
    -webkit-text-stroke: 1px #e25822;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
    font-weight: 900;
    margin-bottom: 0;
}

.watch_now {
    display: flex;
    align-items: center;
    margin-top: 25px;
}

.watch_now h6 {
    margin-bottom: 0;
    margin-right: 25px;
    font-size: 20px;
    font-weight: 600;
}

.watch_now img {
    width: 50px;
}

.release_img {
    animation: rotation 2s infinite linear;
}

/* @keyframes rotation {
    from {
        transform: rotateY(10deg);
    }

    to {
        transform: rotateY(40deg);
    }
} */

@keyframes rotation {
    0% {
        transform: rotateY(20deg);
    }

    /* 25% {
        transform: rotateX(20deg);
    } */

    50% {
        transform: rotateY(30deg);
    }

    /* 75% {
        transform: rotateY(30deg);
    } */

    100% {
        transform: rotateY(20deg);
    }
}

@media (max-width: 767px) {
    .release_text {
        margin-top: 25px;
    }

    .release_text h1 {
        font-size: 35px;
        font-weight: 900;
    }

    .release_text h1 span {
        font-size: 35px;
    }
}