.media_partner h1{
    font-size: 50px;
    font-weight: 900;
    text-align: center;
    color: #fff;
}

.media_partner h1 span{
    -webkit-text-stroke: 1px #e25822;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
    font-weight: 900;
    margin-bottom: 0;
}

.partners_logos{
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    padding: 20px;
}

.media_row{
    padding: 15px;
    border-radius: 0px;
    margin-top: 30px !important;
}

@media (max-width: 767px) {
    .media_partner h1{
        font-size: 45px;
    }
    
    .media_partner h1 span{
        -webkit-text-stroke: 1px #e25822;
        -webkit-text-fill-color: transparent;
        font-size: 45px;
        font-weight: 900;
        margin-bottom: 0;
    }

    .partners_logos{
        flex-direction: column;
        justify-content: center;
    }

    .partners_logos img{
        width: 200px;
        margin: 15px auto;
    }
}